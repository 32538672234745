import React, { useState } from 'react'
import { Link, useSearchParams } from '@remix-run/react'

import { validateLogin } from '../../validate'
import Input from '../../components/forms/Input'
import { error } from '../../components/forms/Input/Input.styles'
import { LoginButton } from '../../components/SessionButtons'

import { LoginErrorCode } from '../../graphql/types.gen'
import { useLogIn, useLogOut } from '../../utilities/userUtils'

import * as style from './LoginForm.styles'
import { Card } from '../../components/Card/Card'

const INTERNAL_ERROR_MESSAGE = 'Something went wrong on our end: '

const LoginForm: React.FC = () => {
  const [searchParams] = useSearchParams()
  let [logIn, loginResult] = useLogIn()

  const emailSearchParam = searchParams.get('email')
  const [email, setEmail] = useState(
    emailSearchParam ? decodeURIComponent(emailSearchParam) : '',
  )
  const [password, setPassword] = useState('')
  const [formErrors, setFormErrors] = useState<{
    email?: string
    password?: string
  }>({})
  const [loginError, setLoginError] = useState<string>()
  const logOut = useLogOut({ shouldNotReload: true })

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (isValid()) {
      setFormErrors({})
      setLoginError(undefined)

      try {
        const loginResult = await logIn({
          variables: {
            loginInput: {
              email,
              password,
            },
          },
        })

        const { ok, error } = loginResult?.data?.login ?? {}

        if (!ok) {
          if (
            error?.code === LoginErrorCode.MultiFactorAuthenticationRequired
          ) {
            // TODO: Handle MFA here
          } else if (error?.code) {
            setLoginError(error.description)
          } else {
            setLoginError(
              INTERNAL_ERROR_MESSAGE + JSON.stringify(error, null, 2),
            )
            logOut()
          }
        }
      } catch (error) {
        // TODO handle error
        console.error(error)
        setLoginError(INTERNAL_ERROR_MESSAGE + JSON.stringify(error, null, 2))
        logOut()
      }
    }
  }

  const isValid = () => {
    const { errors, isValid } = validateLogin({ email, password })

    if (!isValid) {
      setFormErrors(errors)
    }

    return isValid
  }

  const emailConfirmed = Boolean(searchParams.get('confirmed'))

  return (
    <form onSubmit={onSubmit} css={style.form}>
      <Card composeCss={style.card}>
        <Input
          label={emailConfirmed ? 'Email confirmed!' : 'Email'}
          name="email"
          value={email}
          onChange={(e) => {
            setEmail(e.currentTarget.value)
          }}
          error={formErrors.email}
          placeholder="Email"
          type="text"
          focus={!emailConfirmed}
          css={style.input}
          disabled={emailConfirmed}
        />

        <Input
          label="Password"
          name="password"
          value={password}
          onChange={(e) => {
            setPassword(e.currentTarget.value)
          }}
          error={formErrors.password}
          placeholder="Password"
          type="password"
          focus={emailConfirmed}
          css={style.input}
          labelEndElement={
            <Link css={style.info} to="/reset">
              <span>Forgot password?</span>
            </Link>
          }
        />

        <LoginButton
          css={style.loginButton}
          type="submit"
          loading={loginResult.loading}
        />
        <span css={style.info}>
          Don't have an account?{' '}
          <Link css={style.info} to="/signup">
            Create one.
          </Link>
        </span>
        {loginError && <span css={error}>{loginError}</span>}
      </Card>
    </form>
  )
}

export default LoginForm
