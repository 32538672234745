import { css } from '@emotion/react'
import { mTheme } from '../../style/themes'

export const form: Style = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: mTheme.size.S,
})

export const card: Style = css({
  width: '100%',
})

export const input: Style = () => ({
  width: '100%',
})

export const info: Style = css({
  textAlign: 'center',
  '&:hover': {
    color: mTheme.fontColor.link.hover,
  },
  fontSize: mTheme.fontSize.S,
  cursor: 'pointer',
})

export const loginButton: Style = css({
  display: 'flex',
  width: '100%',
  backgroundColor: mTheme.color.primary,
})

export const emailConfirmedMessage: Style = css({
  fontSize: mTheme.fontSize.S,
  color: mTheme.color.success,
})
