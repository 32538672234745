import LoginForm from './LoginForm'
import { Page } from '../../components/Page/Page'

const LoginPage: React.FC = () => {
  return (
    <Page title="Login">
      <LoginForm />
    </Page>
  )
}

export default LoginPage
