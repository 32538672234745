import Button, { ButtonAsLink } from '../Button'

interface LoginButtonProps {
  type: 'link' | 'submit'
  loading?: boolean
  disabled?: boolean
  tabIndex?: number
}

const LoginButton: React.FC<LoginButtonProps> = ({
  type,
  loading,
  disabled,
  ...props
}) => {
  if (type === 'submit') {
    return (
      <Button
        css={{ margin: 0 }}
        primary
        type="submit"
        {...props}
        disabled={loading || disabled}
      >
        {loading ? 'Logging in...' : 'Log in'}
      </Button>
    )
  }

  return (
    <ButtonAsLink css={{ margin: 0 }} primary to="/login" {...props}>
      Log in
    </ButtonAsLink>
  )
}

export default LoginButton
